import React from "react";
import { Routes, Route } from "react-router-dom";
import { Exchange, Trade } from "../../components/pages";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Exchange />} />
      {/* <Route path="trade" element={<Trade />} /> */}
    </Routes>
  );
};

export default PublicRoutes;
