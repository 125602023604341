import axios from "axios";

export { _fetch as fetch };

function handleError(error, reject) {
  if (error) {
    console.log("Api Error : ", error);
  }
  if (error && error.status === 401) {
    localStorage.clear();
    window.location.reload();
    return;
  }
  reject(error);
  return;
}

function handleResponse(successs, resolve) {
  resolve(successs);
  return;
}

function setMehod(method, path, body, options, params) {
  let config = {};
  if (options) {
    if (options.jwt) {
      config.headers = {
        "api-access-token": options.jwt,
      };
    }
  }
  params = params ? "?" + new URLSearchParams(params).toString() : "";
  if (method === "get" || method === "delete") {
    return axios[method](`${path}${params}`, config);
  }
  if (method === "post" || method === "put") {
    // let data = requestEncryption(body);
    return axios[method](`${path}`, body, config);
  }
}

/**
 * Fetch Api Data from Backend
 * @param {*} method : Methode Name (get,post,put)
 * @param {*} path : Url Path
 * @param {*} body : Body Object
 * @param {*} options : Others
 * @param {*} params : Query Params data
 * @returns
 */
function _fetch(method, path, body, options, params) {
  return new Promise((resolve, reject) => {
    return setMehod(method, path, body, options, params)
      .then(function (response) {
        handleResponse(response, resolve);
        return;
      })
      .catch(function (error) {
        handleError(error.response, reject);
        return;
      });
  });
}
