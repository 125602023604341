import { actionTypes } from "../_actions/user.action";

const initialState = {
  walletAddress: "",
  userWallets: [],
  selectedPair: {},
  selectedPairIndex: 0,
  decimal: 4,
  depthOption: true,
  favPairList: [],
  selectedPairScroll: "",
};
const user = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: action.data,
      };
    case actionTypes.SAVE_USER_WALLETS:
      return {
        ...state,
        userWallets: action.data,
      };
    case actionTypes.SAVE_SELECTED_PAIR:
      return {
        ...state,
        selectedPair: { ...action.data },
        selectedPairScroll: action.data.pair_key,
      };
    case actionTypes.SAVE_SELECTED_PAIR_INDEX:
      return {
        ...state,
        selectedPairIndex: action.data,
      };
    case actionTypes.SAVE_DECIMAL:
      return {
        ...state,
        decimal: action.data,
      };
    case actionTypes.SAVE_DEPTH_OPTION:
      return {
        ...state,
        depthOption: action.data,
      };
    case actionTypes.SAVE_FAV_PAIR_LIST:
      return {
        ...state,
        favPairList: action.data,
      };
    default:
      return state;
  }
};

export default user;
