import { MARKET_TRADES_SIZE } from "../../constants/constants";
import { getAccountBalance } from "../../_utils/comman";
import { orderAdd, orderSub } from "../../_utils/comman";

/** seting action types */
export const actionTypes = {
  BUY_ORDERS: "BUY_ORDERS",
  SELL_ORDERS: "SELL_ORDERS",
  SAVE_LTP: "SAVE_LTP",
  SAVE_MARKET_TRADES: "SAVE_MARKET_TRADES",
  SAVE_PAIR_STAT: "SAVE_PAIR_STAT",
  LASTPRICE: "LASTPRICE",
  TRADEORDER: "TRADEORDER",
  SAVE_USER_ORDER_HISTORY: "SAVE_USER_ORDER_HISTORY",
  SAVE_USER_OPEN_ORDER: "SAVE_USER_OPEN_ORDER",
  STATE_DATA_UPDATE: "STATE_DATA_UPDATE",
  SAVE_DEPOSIT_BALANCE: "SAVE_DEPOSIT_BALANCE",
  SAVE_ORDER_PRICE: "SAVE_ORDER_PRICE",
  SAVE_ORDER_AMOUNT: "SAVE_ORDER_AMOUNT",
  SAVE_USER_TRADE_HISTORY: "SAVE_USER_TRADE_HISTORY",
  SAVE_INITIAL_STATE: "SAVE_INITIAL_STATE",
  SAVE_INITIAL_WALLET_STATE: "SAVE_INITIAL_WALLET_STATE",
  SAVE_LIST_OF_PAIRS: "SAVE_LIST_OF_PAIRS",
  SAVE_USER_WALLETS_BALANCES: "SAVE_USER_WALLETS_BALANCES",
  SAVE_PAIR_DECIMALS: "SAVE_PAIR_DECIMALS",

  SAVE_BUY_SELL_TAB: "SAVE_BUY_SELL_TAB",
  SAVE_LIMIT_MARKET_TAB: "SAVE_LIMIT_MARKET_TAB",
  SAVE_STOP_WALLET_TIME_INTERVAL: "SAVE_STOP_WALLET_TIME_INTERVAL",
};

/*
 * Action creators for login
 */

export const saveBuySellTab = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SAVE_BUY_SELL_TAB, data: data });
};

export const saveLimitMarketTab = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SAVE_LIMIT_MARKET_TAB, data: data });
};

export const saveOrderPrice = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SAVE_ORDER_PRICE, data: data });
};

export const saveOrderAmount = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SAVE_ORDER_AMOUNT, data: data });
};

export const exchangeSellOrder = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SELL_ORDERS, data: data });
};

export const exchangeBuyOrder = (data) => (dispatch) => {
  dispatch({ type: actionTypes.BUY_ORDERS, data: data });
};

export const saveTradeOrder = (data) => (dispatch) => {
  dispatch({ type: actionTypes.TRADEORDER, data: data });
};

export const saveLastPrice = (data) => (dispatch) => {
  dispatch({ type: actionTypes.LASTPRICE, data: data });
};

export const saveOpenOrders = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SAVE_USER_OPEN_ORDER, data: data });
};

export const saveUserOrderHistory = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SAVE_USER_ORDER_HISTORY, data: data });
};

export const saveUserTradeHistory = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SAVE_USER_TRADE_HISTORY, data: data });
};

export const saveStats = (data) => (dispatch) => {
  dispatch({ type: actionTypes.STATE_DATA_UPDATE, data: data });
};

export const saveDepositBalance = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SAVE_DEPOSIT_BALANCE, data: data });
};

export const saveOrder = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SAVE_ORDER_PRICE, data: data });
};

export const savePairDecimals = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SAVE_PAIR_DECIMALS, data: data });
};

export const saveStopWalletTimeInterval = (data) => (dispatch) => {
  dispatch({ type: actionTypes.SAVE_STOP_WALLET_TIME_INTERVAL, data: data });
};

export const saveInitialState = (data) => (dispatch) => {
  if (data === "pair") {
    dispatch({ type: actionTypes.SAVE_INITIAL_STATE });
  }
  if (data === "wallet") {
    dispatch({ type: actionTypes.SAVE_INITIAL_WALLET_STATE });
  }
};

export const saveListOfPairs = (type, data) => (dispatch, getState) => {
  const listOfPairs = getState().exchange.listOfPairs;
  let pairsData = listOfPairs;

  for (const i in data) {
    if (type === "api") {
      const oldItemIndex = listOfPairs.findIndex(
        (doc) => doc.pair_key === data[i].pair_key
      );
      if (oldItemIndex === -1) {
        pairsData[i] = data[i];
      } else {
        pairsData[oldItemIndex] = Object.assign(
          pairsData[oldItemIndex],
          data[i]
        );
      }
    } else {
      const oldItemIndex = listOfPairs.findIndex(
        (doc) => doc.pair_key === data[i].pair
      );
      if (oldItemIndex === -1) {
        pairsData[i] = data[i];
      } else {
        pairsData[oldItemIndex] = Object.assign(
          pairsData[oldItemIndex],
          data[i]
        );
      }
    }
  }

  dispatch({ type: actionTypes.SAVE_LIST_OF_PAIRS, data: pairsData });
};

export const searchListOfPairs = (data) => (dispatch, getState) => {
  const listOfPairs = getState().exchange.listOfPairs;
  let pairsData = data;

  for (const i in data) {
    const oldItemIndex = listOfPairs.findIndex(
      (doc) => doc.pair_key === data[i].pair_key
    );
    pairsData[i] = listOfPairs[oldItemIndex];
  }
  return pairsData;
};

export const saveUserWalletsBalances =
  (walletData, base_id, base_decimal, price_id, price_decimal) =>
  async (dispatch) => {
    try {
      const userWalletsBalances = [];
      for (const wallet of walletData) {
        await getAccountBalance(
          wallet?.address,
          base_id,
          base_decimal,
          price_id,
          price_decimal
        )
          .then((res) => {
            userWalletsBalances.push(res);
          })
          .catch((res) => {
            userWalletsBalances.push(res);
          });
      }
      dispatch({
        type: actionTypes.SAVE_USER_WALLETS_BALANCES,
        data: userWalletsBalances,
      });
    } catch (error) {}
  };

export const saveExchangeOrder = (orderRes) => (dispatch, getState) => {
  let uniqueId = new Date().valueOf();
  const order = [orderRes[2][0], orderRes[2][1], orderRes[2][2], uniqueId];
  if (orderRes[1] === 0) {
    let buyOrders = getState().exchange.buyOrders.slice();
    if (orderRes[0] === "add") {
      buyOrders = orderAdd(order, buyOrders, "B");
    } else {
      buyOrders = orderSub(order, buyOrders);
    }

    dispatch(exchangeBuyOrder(buyOrders));
  } else {
    let sellOrders = getState().exchange.sellOrders.slice();
    if (orderRes[0] === "add") {
      sellOrders = orderAdd(order, sellOrders, "S");
    } else {
      sellOrders = orderSub(order, sellOrders);
    }
    dispatch(exchangeSellOrder(sellOrders));
  }
};

export const saveExchangeTrade = (response) => (dispatch, getState) => {
  const order = [
    response[0][2],
    response[0][3],
    response[0][2] * response[0][3],
  ];
  if (response[0][8] === 0) {
    let buyOrders = getState().exchange.buyOrders.slice();
    buyOrders = orderSub(order, buyOrders);
    dispatch(exchangeBuyOrder(buyOrders));
  } else {
    let sellOrders = getState().exchange.sellOrders.slice();
    sellOrders = orderSub(order, sellOrders);
    dispatch(exchangeSellOrder(sellOrders));
  }
};

export const saveExchangeTradeOnTrades = (response) => (dispatch, getState) => {
  let markTradData = getState().exchange?.marketTrades.slice();

  const trade = [
    response[0][2], // price
    response[0][3], // amount
    response[0][4], // date
    response[0][5], //
    response[0][6], //
    response[0][8], // side => B/S
    response[0][9], // uniqe key
  ];

  let currentLtp = trade[0];
  let lastLtp = markTradData[0][0];
  markTradData.unshift(trade);
  if (markTradData.length > MARKET_TRADES_SIZE) {
    markTradData.pop();
  }
  dispatch({
    type: actionTypes.SAVE_LTP,
    data: {
      currentLtp,
      lastLtp,
    },
  });

  dispatch({
    type: actionTypes.SAVE_MARKET_TRADES,
    data: markTradData,
  });
};
