import { combineReducers } from "redux";
import persist from "./persist.reducer";
import exchange from "./exchange.reducer";
import user from "./user.reducer";
const appReducer = combineReducers({
  persist,
  exchange,
  user,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USERS_PERSIST") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
