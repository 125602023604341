import { API_HOST, INDEXER_API } from "../constants/constants";
import { fetch } from "./fetch";

const getPairList = async (data) => {
  try {
    const responce = await fetch(
      "post",
      `${API_HOST}/trading/trade/pair-list`,
      data
    );
    return responce.data;
  } catch (error) {
    throw error;
  }
};

const favouritePairList = async (data) => {
  try {
    const responce = await fetch(
      "post",
      `${API_HOST}/trading/trade/favourite`,
      data
    );
    return responce.data;
  } catch (error) {
    throw error;
  }
};

const getPairTradeHistory = async (data) => {
  try {
    const responce = await fetch(
      "post",
      `${API_HOST}/trading/trade/history_trading`,
      data
    );
    return responce.data;
  } catch (error) {
    console.log("Get Pair Trading History Error : ", error);
    throw error;
  }
};

const getAccountAlgoMinBalance = async (address) => {
  try {
    const responce = await fetch(
      "get",
      `${INDEXER_API}/v2/accounts/${address}?include-all=true`
    );
    return responce.data;
  } catch (error) {
    throw { message: "Something went wrong." };
  }
};

//exporting
export const ApiServices = {
  getPairList,
  favouritePairList,
  getPairTradeHistory,
  getAccountAlgoMinBalance,
};
