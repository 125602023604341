/** seting action types */
export const actionTypes = {
  SAVE_WALLET_ADDRESS: "SAVE_WALLET_ADDRESS",
  SAVE_USER_WALLETS: "SAVE_USER_WALLETS",
  SAVE_SELECTED_PAIR: "SAVE_SELECTED_PAIR",
  SAVE_SELECTED_PAIR_INDEX: "SAVE_SELECTED_PAIR_INDEX",
  SAVE_DECIMAL: "SAVE_DECIMAL",
  SAVE_DEPTH_OPTION: "SAVE_DEPTH_OPTION",
  SAVE_BUY: "SAVE_BUY",
  SAVE_SELL: "SAVE_SELL",
  SAVE_FAV_PAIR_LIST: "SAVE_FAV_PAIR_LIST",
};

/*
 * Action creators for login
 */

export const saveSelectedPair = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_SELECTED_PAIR,
    data: data,
  });
};

export const saveSelectedPairIndex = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_SELECTED_PAIR_INDEX,
    data: data,
  });
};

export const saveBuy = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_BUY,
    data: data,
  });
};

export const saveSell = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_SELL,
    data: data,
  });
};

export const saveFavPairsList = (data) => (dispatch, getState) => {
  try {
    const favList = getState().user.favPairList;
    let newfavList = [];
    if (favList) {
      newfavList = favList;
    }
    const { address, pair_key } = data;

    const oldFavindex = favList?.findIndex(
      (doc) => doc.address === address && doc.pair_key === pair_key
    );
    if (oldFavindex === -1) {
      newfavList.push(data);
    } else {
      newfavList.splice(oldFavindex, 1);
    }
    dispatch({
      type: actionTypes.SAVE_FAV_PAIR_LIST,
      data: newfavList,
    });
  } catch (error) {
    console.log("Error saveFavPairsList :", error);
  }
};
