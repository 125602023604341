import { FONT_LAYOUT, GRID_LAYOUT } from "../../constants/grid.layout";
import { actionTypes } from "../_actions/persist.action";

const initialState = {
  grid_layout: GRID_LAYOUT,
  old_grid_layout: {},
  font_layout: FONT_LAYOUT,
  mobile_tab: "MARKET",
  current_breakpoint: "lg",
};

// GET_KYC_DATA_SUMSUB
const persist = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_CHANGES_GRID_LAYOUT:
      return {
        ...state,
        grid_layout: action.data,
      };
    case actionTypes.SAVE_OLD_GRID_LAYOUT:
      return {
        ...state,
        old_grid_layout: action.data,
      };
    case actionTypes.SAVE_CHANGES_FONT_SIZE_LAYOUT:
      return {
        ...state,
        font_layout: action.data,
      };

    case actionTypes.SAVE_MOBILE_TAB:
      return {
        ...state,
        mobile_tab: action.data,
      };
    case actionTypes.SAVE_CURRENT_BREAKPOINT:
      return {
        ...state,
        current_breakpoint: action.data,
      };
    case "RESET_LAYOUT":
      return {
        grid_layout: GRID_LAYOUT,
        font_layout: FONT_LAYOUT,
        mobile_tab: "MARKET",
        current_breakpoint: "lg",
        old_grid_layout: {},
      };

    default:
      return state;
  }
};

export default persist;
